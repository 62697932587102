import React from "react"
import Layout from "../../Components/layout"
import SEO from "../../Components/SEO"

export default function PrivacyPolicy() {
  return (
    <Layout>
      <SEO title="Privacy Policy" description="Privacy Policy"></SEO>
      <div className="policy">
        <h1>PRIVACY POLICY</h1>
        <p>
          This Privacy Policy describes how fastlink.ph collects, uses, and
          discloses your Personal Information when you visit or make a purchase
          from the Site.
        </p>
        <h2>Collecting Personal Information</h2>
        <p>
          When you visit the Site, we collect certain information about your
          device, your interaction with the Site, and information necessary to
          process your purchases. We may also collect additional information if
          you contact us for customer support. In this Privacy Policy, we refer
          to any information that can uniquely identify an individual (including
          the information below) as “Personal Information”. See the list below
          for more information about what Personal Information we collect and
          why.
        </p>
        <ul>
          <h3>Device information</h3>
          <li>
            <strong>Examples of Personal Information collected:</strong> version
            of web browser, IP address, time zone, cookie information, what
            sites or products you view, search terms, and how you interact with
            the Site.
          </li>
          <li>
            <strong>Purpose of collection:</strong> to load the Site accurately
            for you, and to perform analytics on Site usage to optimize our
            Site.
          </li>
          <li>
            <strong>Source of collection:</strong> Collected automatically when
            you access our Site using cookies, log files, web beacons, tags, or
            pixels
          </li>
        </ul>
        <ul>
          <h3>Order information</h3>
          <li>
            <strong>Examples of Personal Information collected:</strong> name,
            billing address, shipping address, payment information (including
            credit card numbers, email address, and phone number.
          </li>
          <li>
            <strong>Purpose of collection:</strong> to provide products or
            services to you to fulfill our contract, to process your payment
            information, arrange for shipping, and provide you with invoices
            and/or order confirmations, communicate with you, screen our orders
            for potential risk or fraud, and when in line with the preferences
            you have shared with us, provide you with information or advertising
            relating to our products or services.
          </li>
          <li>
            <strong>Source of collection:</strong> collected from you.
          </li>
        </ul>
        <ul>
          <h3>Customer support information</h3>
          <li>
            <strong>Examples of Personal Information collected:</strong> name,
            billing address, shipping address, payment information (including
            credit card numbers, email address, and phone number.
          </li>
          <li>
            <strong>Purpose of collection:</strong> to provide customer support.
          </li>
          <li>
            <strong>Source of collection:</strong> collected from you.
          </li>
        </ul>
        <h2>Sharing Personal Information</h2>
        <p>
          We do not sell, trade, or otherwise transfer to outside parties your
          personally information. This does not include third parties that help
          us run our website and provide the best user experience, but also
          agrees to keep everything confidential. We may share your Personal
          Information to comply with applicable laws and regulations, to respond
          to a subpoena, search warrant or other lawful request for information
          we receive, or to otherwise protect our rights. These Privacy Policy
          and any separate agreements whereby we provide you Services shall be
          governed by and construed in accordance with the Data Privacy Act of
          2012 and other laws of the Philippines.
        </p>
        <h2>Data Security</h2>
        <p>
          Security measures are present whenever a form is submitted through our
          website, payment gateways, and processing of orders. All inputted card
          payment information goes through our SSL certificate to be able to
          keep all of it confidential. Basic information such as, but not
          limited to: Name, address, email, and contact number will be present
          in order to process and deliver your products/services.
        </p>
        <h2>Data Security</h2>
        <p>
          As described above, we use your Personal Information to provide you
          with targeted advertisements or marketing communications we believe
          may be of interest to you. For example:
        </p>
        <ul>
          <li>
            We use Google Analytics to help us understand how our customers use
            the Site. You can read more about how Google uses your Personal
            Information here:{" "}
            <a href="https://policies.google.com/privacy?hl=en">
              https://policies.google.com/privacy?hl=en
            </a>
            . You can also opt-out of Google Analytics here:{" "}
            <a href="https://tools.google.com/dlpage/gaoptout">
              https://tools.google.com/dlpage/gaoptout
            </a>
            .
          </li>
          <li>
            We use Facebook Pixel to help us understand how our customers use
            the Site.
          </li>
          <li>
            We share information about your use of the Site, your purchases, and
            your interaction with our ads on other websites with our advertising
            partners. We collect and share some of this information directly
            with our advertising partners, and in some cases through the use of
            cookies or other similar technologies (which you may consent to,
            depending on your location).
          </li>
        </ul>
        <p>
          For more information about how targeted advertising works, you can
          visit the Network Advertising Initiative’s (“NAI”) educational page at
          <a href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work">
            http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work
          </a>
          .
        </p>
        <ul>
          <p>You can opt out of targeted advertising by:</p>
          <li>
            FACEBOOK -{" "}
            <a href="https://www.facebook.com/settings/?tab=ads">
              https://www.facebook.com/settings/?tab=ads
            </a>
          </li>
          <li>
            GOOGLE -{" "}
            <a href="https://www.google.com/settings/ads/anonymous">
              {" "}
              https://www.google.com/settings/ads/anonymous
            </a>
          </li>
        </ul>
        <h2>Using Personal Information</h2>
        <p>
          We use your personal Information to provide our services to you which
          is collected from the forms that are present in our website, and
          tracked with the use of Google Analytics and Facebook Pixel which
          includes: offering products for sale, processing payments, shipping
          and fulfillment of your order, and keeping you up to date on new
          products, services, and offers.
        </p>
        <h2>Cookies</h2>
        <p>
          A cookie is a small amount of information that’s downloaded to your
          computer or device when you visit our Site. We use a number of
          different cookies, including functional, performance, advertising,
          purchase actions, and social media or content cookies. Cookies make
          your browsing experience better by allowing the website to remember
          your actions and preferences (such as login and region selection).
          This means you don’t have to re-enter this information each time you
          return to the site or browse from one page to another. Cookies also
          provide information on how people use the website, for instance
          whether it’s their first time visiting or if they are a frequent
          visitor.
        </p>
        <h2>Changes</h2>
        <p>
          We may update this Privacy Policy from time to time in order to
          reflect, for example, changes to our practices or for other
          operational, legal, or regulatory reasons.
        </p>
        <h2>Contact</h2>
        <p>
          Last updated: April 29, 2021 <br />
          For more information about our privacy practices, if you have
          questions, please contact us by e-mail at inquiry@fastlink.ph
        </p>
      </div>
    </Layout>
  )
}
